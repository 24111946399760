import { Flex } from 'antd';
import classNames from 'classnames';
import { useOrderDishContext } from 'contexts/OrderDishContext';
import { formatPriceToVietnameseCurrency } from 'utils/currency';

export const OrderMenuItems = () => {
  const { dishItems, activeProductId, handleSelectProduct } = useOrderDishContext();
  const isEmpty = !dishItems.length;

  return (
    <div className={classNames('order-menu-items', isEmpty && 'menu-items-empty')}>
      {!isEmpty ? (
        dishItems.map(item => {
          const itemPrice = formatPriceToVietnameseCurrency(item.sale_price, 'đ');

          return (
            <Flex
              key={item.product_id}
              className={classNames(
                'menu-item',
                activeProductId === item.product_id && 'menu-item-active'
              )}
              vertical
              gap={4}
              onClick={() => handleSelectProduct(item)}
            >
              <span className="menu-item-name">{item.product_name}</span>
              <span className="menu-item-price">{itemPrice}</span>
            </Flex>
          );
        })
      ) : (
        <Flex className="items-empty" align="center" justify="center">
          Empty
        </Flex>
      )}
    </div>
  );
};
