import { useQueryClient } from '@tanstack/react-query';
import { Button, Flex } from 'antd';
import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { useGetMe } from 'hooks/useGetMe';
import PAGES from 'routes/constants';
import QUERY_KEYS from 'services/api/queryKeys';
import { clearToken } from 'services/storages/userStorage';

import { ReactComponent as ArrowLeftIcon } from 'assets/images/arrow-left-icon.svg';
import { ReactComponent as Avatar } from 'assets/images/avatar.svg';
import { ReactComponent as Logo } from 'assets/images/logo-header.svg';
import { ReactComponent as Logout } from 'assets/images/logout.svg';
import 'styles/common.scss';

function Header() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: myInfo } = useGetMe();
  const [searchParams] = useSearchParams();
  const location = useLocation();

  const isOrderDishPage = location.pathname.includes(PAGES.orderDish);
  const tableId = searchParams.get('tableId');

  const handleLogout = () => {
    clearToken();
    navigate(PAGES.login);
    queryClient.removeQueries({ queryKey: [QUERY_KEYS.GET_ME] });
  };

  const handleClickBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const actionItem = useMemo(() => {
    if (!isOrderDishPage) {
      return null;
    }

    if (tableId) {
      return (
        <>
          <Flex className="btn-back" align="center" gap={8} onClick={handleClickBack}>
            <ArrowLeftIcon />
            Đổi bàn ăn
          </Flex>

          <Flex className="table-info">Bàn số {tableId}</Flex>
        </>
      );
    }

    return (
      <Flex className="btn-back" align="center" gap={4} onClick={handleClickBack}>
        <ArrowLeftIcon />
        Trở về
      </Flex>
    );
  }, [tableId, isOrderDishPage, handleClickBack]);

  return (
    <header className="pos-app-header">
      <div className="pos-app-header__inner">
        <Link to={PAGES.home} className="pms-logo">
          <Logo />
        </Link>

        <Flex className="header-actions" align="center" justify="space-between">
          <div className="center-actions">{actionItem}</div>

          <div className="flex items-center">
            <Avatar />
            {!_.isEmpty(myInfo) ? (
              <>
                <Button
                  className="btn-login"
                  type="text"
                  style={{ paddingRight: 0, paddingLeft: 8 }}
                >
                  {myInfo?.email || 'Admin'}
                </Button>
                <Button className="btn-logout" type="text" onClick={handleLogout}>
                  <Logout />
                </Button>
              </>
            ) : (
              <span className="login-text">Đăng nhập</span>
            )}
          </div>
        </Flex>
      </div>
    </header>
  );
}
export default Header;
