import { createHashRouter } from 'react-router-dom';

import App from 'App';
import LoginRegister from 'pages/login-register';

import OrderDish from 'pages/order-dish';
import TablesList from 'pages/tables-list';
import PAGES, { PATTERN_URL } from './constants';
import PrivateRoutes from './privateRoutes';

// function Redirect({ to }: { to: string }) {
//   const navigate = useNavigate();
//   useEffect(() => {
//     navigate(to);
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);
//   return null;
// }

export const router = createHashRouter([
  {
    path: PATTERN_URL.HOME,
    element: (
      <PrivateRoutes>
        <App />
      </PrivateRoutes>
    ),
    children: [
      {
        path: PAGES.home,
        element: <TablesList />
      },
      {
        path: PAGES.orderDish,
        element: <OrderDish />
      }
    ]
  },
  {
    path: PAGES.login,
    element: <LoginRegister />
    // loader: rootLoader,
  },
  {
    path: PAGES.register,
    element: <LoginRegister />
    // loader: rootLoader,
  }
]);
