import * as category from './module/category.api';
import * as floor from './module/floor.api';
import * as user from './module/user.api';

const apis = {
  user,
  floor,
  category
};

export default apis;
