import { Flex } from 'antd';
import { ReactComponent as CalculatorDeleteIcon } from 'assets/images/calculator-delete-icon.svg';
import classNames from 'classnames';
import { useOrderDishContext } from 'contexts/OrderDishContext';
import { useState } from 'react';

const ActionTypes = {
  QUANTITY: 'Qty',
  DISCOUNT: '% Dis',
  PRICE: 'Giá'
};

export const OrderPaymentCalculator = () => {
  const {
    handleUpdateOrderedProductQuantity,
    handleUpdateOrderedProductPrice,
    handleRemoveOrderedProductPrice
  } = useOrderDishContext();
  const [currentAction, setCurrentAction] = useState(ActionTypes.QUANTITY);

  const handleClickCalculatorNumber = (value: string) => {
    switch (currentAction) {
      case ActionTypes.QUANTITY:
        const quantity = Number.parseInt(value);
        handleUpdateOrderedProductQuantity(quantity);
        break;
      case ActionTypes.DISCOUNT:
        break;
      case ActionTypes.PRICE:
        handleUpdateOrderedProductPrice(value);
        break;
      default:
    }
  };

  const handleChangeAction = (action: string) => {
    if (action === ActionTypes.PRICE) {
      handleRemoveOrderedProductPrice();
    }
    setCurrentAction(action);
  };

  return (
    <div className="payment-calculator">
      <Flex
        className="calculator-item calculator-number"
        align="center"
        justify="center"
        onClick={() => handleClickCalculatorNumber('1')}
      >
        1
      </Flex>
      <Flex
        className="calculator-item calculator-number"
        align="center"
        justify="center"
        onClick={() => handleClickCalculatorNumber('2')}
      >
        2
      </Flex>
      <Flex
        className="calculator-item calculator-number"
        align="center"
        justify="center"
        onClick={() => handleClickCalculatorNumber('3')}
      >
        3
      </Flex>
      <Flex
        className={classNames(
          'calculator-item',
          'calculator-action',
          currentAction === ActionTypes.QUANTITY && 'calculator-item-active'
        )}
        align="center"
        justify="center"
        onClick={() => handleChangeAction(ActionTypes.QUANTITY)}
      >
        {ActionTypes.QUANTITY}
      </Flex>

      <Flex
        className="calculator-item calculator-number"
        align="center"
        justify="center"
        onClick={() => handleClickCalculatorNumber('4')}
      >
        4
      </Flex>
      <Flex
        className="calculator-item calculator-number"
        align="center"
        justify="center"
        onClick={() => handleClickCalculatorNumber('5')}
      >
        5
      </Flex>
      <Flex
        className="calculator-item calculator-number"
        align="center"
        justify="center"
        onClick={() => handleClickCalculatorNumber('6')}
      >
        6
      </Flex>
      <Flex
        className={classNames(
          'calculator-item',
          'calculator-action',
          currentAction === ActionTypes.DISCOUNT && 'calculator-item-active'
        )}
        align="center"
        justify="center"
        onClick={() => handleChangeAction(ActionTypes.DISCOUNT)}
      >
        {ActionTypes.DISCOUNT}
      </Flex>

      <Flex
        className="calculator-item calculator-number"
        align="center"
        justify="center"
        onClick={() => handleClickCalculatorNumber('7')}
      >
        7
      </Flex>
      <Flex
        className="calculator-item calculator-number"
        align="center"
        justify="center"
        onClick={() => handleClickCalculatorNumber('8')}
      >
        8
      </Flex>
      <Flex
        className="calculator-item calculator-number"
        align="center"
        justify="center"
        onClick={() => handleClickCalculatorNumber('9')}
      >
        9
      </Flex>
      <Flex
        className={classNames(
          'calculator-item',
          'calculator-action',
          currentAction === ActionTypes.PRICE && 'calculator-item-active'
        )}
        align="center"
        justify="center"
        onClick={() => handleChangeAction(ActionTypes.PRICE)}
      >
        {ActionTypes.PRICE}
      </Flex>

      <Flex
        className="calculator-item calculator-number"
        align="center"
        justify="center"
        onClick={() => handleClickCalculatorNumber('0')}
      >
        +/-
      </Flex>
      <Flex
        className="calculator-item calculator-number"
        align="center"
        justify="center"
        onClick={() => handleClickCalculatorNumber('00')}
      >
        0
      </Flex>
      <Flex
        className="calculator-item calculator-number calculator-dot"
        align="center"
        justify="center"
      >
        .
      </Flex>
      <Flex className="calculator-item" align="center" justify="center">
        <CalculatorDeleteIcon />
      </Flex>
    </div>
  );
};
