import { useQuery } from '@tanstack/react-query';
import { matchPath, useLocation } from 'react-router-dom';

import API from 'services/api/index';
import QUERY_KEYS from 'services/api/queryKeys';
import PAGES from 'routes/constants';

export const useGetMe = () => {
  const location = useLocation();
  const { data, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.GET_ME],
    staleTime: 1000 * 60,
    retry: 0,
    enabled: !matchPath(PAGES.login, location.pathname),
    queryFn: async () => {
      const me = await API.user.getMe();
      return me;
    }
  });

  return {
    data,
    isLoading
  };
};
