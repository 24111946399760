import { Flex } from 'antd';
import { OrderPaymentCalculator } from './OrderPaymentCalculator';

export const OrderPayment = () => {
  return (
    <Flex className="order-payment" vertical>
      <Flex>
        <Flex
          className="order-payment-action order-payment-action-active"
          align="center"
          justify="center"
          flex={1}
        >
          Order
        </Flex>
        <Flex className="order-payment-action" align="center" justify="center" flex={1}>
          Thanh Toán
        </Flex>
      </Flex>

      <OrderPaymentCalculator />
    </Flex>
  );
};
