export const paths = {
  login() {
    return '/auth/login';
  },
  me() {
    return '/auth/me';
  },
  changePassword() {
    return '/users/change-password';
  },
  floors() {
    return '/pos/floors';
  },
  categories() {
    return '/pos/categories';
  }
};
