import { Flex } from 'antd';
import classNames from 'classnames';
import { useOrderDishContext } from 'contexts/OrderDishContext';

export const OrderMenuTypes = () => {
  const { currentCategory, categories, handleUpdateCurrentCategory } = useOrderDishContext();

  return (
    <Flex className="order-menu-types">
      {categories.map(category => (
        <Flex
          key={category.category_id}
          align="center"
          justify="center"
          className={classNames(
            'menu-type-item',
            currentCategory === category && 'menu-type-item-active'
          )}
          onClick={() => handleUpdateCurrentCategory(category.category_id)}
        >
          {category.category_name}
        </Flex>
      ))}
    </Flex>
  );
};
