export const MESSAGE_CODE: Record<string, string> = {
  // REQUIRED_EMAIL: "Common.ValidateMessage.RequiredEmail",
  // WRONG_FORMAT_EMAIL: "Common.ValidateMessage.WrongEmail",
  // REQUIRED_PASSWORD: "Common.ValidateMessage.RequiredPassword",
  REQUIRED_EMAIL: 'Hãy nhập email của bạn',
  WRONG_FORMAT_EMAIL: 'Email của bạn chưa đúng',
  REQUIRED_PASSWORD: 'Hãy nhập mật khẩu của bạn',
  REQUIRED_FULL_NAME: 'Hãy nhập họ và tên',
  REQUIRED_DATE_TIME: 'Hãy chọn ngày giờ',
  REQUIRED_LAST_NAME: 'Hãy nhập họ',
  REQUIRED_FIRST_NAME: 'Hãy nhập tên',
  REQUIRED_PHONE_NUMBER: 'Hãy nhập số điện thoại của bạn',
  REQUIRED_OTP: 'Hãy nhập mã xác nhận',
  REQUIRED_SERVICE_NAME: 'Hãy nhập tên dịch vụ',
  REQUIRED_SERVICE_NAME_PRICE: 'Hãy nhập giá dịch vụ',
  REQUIRED_REASON_FIX_ROOM: 'Hãy nhập lý do',
  REQUIRED_CUSTOMER: 'Hãy chọn người nhận',
  REQUIRED_EXPENSE_TYPE: 'Hãy chọn nội dung chi',
  REQUIRED_AMOUNT: 'Hãy nhập tổng tiền',

  REQUIRED_CURRENT_PASSWORD: 'Hãy nhập mật khẩu hiện tại',
  REQUIRED_NEW_PASSWORD: 'Hãy nhập mật khẩu mới',
  REQUIRED_NEW_PASSWORD_MIN: 'Mật khẩu cần 6 ký tự trở lên',
  REQUIRED_CONFIRM_PASSWORD: 'Hãy nhập xác nhận mật khẩu mới',
  PASSWORD_NOT_MATCH: 'Mật khẩu xác nhận chưa trùng khớp',

  INVALID_CREDS: 'Sai email hoặc mật khẩu'
};
