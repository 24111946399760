import { Flex } from 'antd';
import { useOrderDishContext } from 'contexts/OrderDishContext';
import { useMemo } from 'react';
import { formatPriceToVietnameseCurrency } from 'utils/currency';

export const OrderedPriceSummary = () => {
  const { tax, orderedProducts } = useOrderDishContext();

  const temporaryTotal = useMemo(() => {
    const totalPrice = orderedProducts.reduce(
      (totalPrice, orderedItem) => totalPrice + orderedItem.sale_price * orderedItem.quantity,
      0
    );
    return totalPrice;
  }, [orderedProducts]);

  const total = temporaryTotal + (temporaryTotal * tax) / 100;

  const temporaryTotalText = temporaryTotal
    ? formatPriceToVietnameseCurrency(temporaryTotal, 'đ')
    : 0;
  const totalText = total ? formatPriceToVietnameseCurrency(total, 'đ') : 0;

  return (
    <div className="ordered-summary-price">
      <Flex className="summary-temporary" gap={4} vertical>
        <Flex align="center" justify="space-between">
          <span>Tạm tính</span>
          <span className="summary-temporary-value">{temporaryTotalText}</span>
        </Flex>

        <Flex align="center" justify="space-between">
          <span>Tax</span>
          <span className="summary-temporary-value">{tax}%</span>
        </Flex>
      </Flex>

      <Flex className="summary-total" align="center" justify="center" gap={4} vertical>
        <span>Tổng</span>
        <span className="summary-total-money">{totalText}</span>
      </Flex>
    </div>
  );
};
