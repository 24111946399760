import { Flex } from 'antd';
import { useOrderDishContext } from 'contexts/OrderDishContext';
import { OrderedItem } from './OrderedItem';
import { OrderedPriceSummary } from './OrderedPriceSummary';

export const OrderedSummary = () => {
  const { orderedProducts } = useOrderDishContext();

  return (
    <>
      <Flex className="ordered-summary-items" flex={1} vertical gap={20}>
        <Flex vertical>
          {orderedProducts.map((product, index) => (
            <OrderedItem key={index} {...product} />
          ))}
        </Flex>
      </Flex>

      <OrderedPriceSummary />
    </>
  );
};
