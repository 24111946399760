import { Flex } from 'antd';
import { ReactComponent as MinusIconSmall } from 'assets/images/minus-icon-small.svg';
import { ReactComponent as PlusIconSmall } from 'assets/images/plus-icon-small.svg';
import classNames from 'classnames';
import { OrderedProduct, useOrderDishContext } from 'contexts/OrderDishContext';
import { formatPriceToVietnameseCurrency } from 'utils/currency';

export const OrderedItem = (product: OrderedProduct) => {
  const { handleSelectProduct, handleUnSelectProduct, activeProductId, setActiveProductId } =
    useOrderDishContext();

  const handleSelectItem = () => {
    setActiveProductId(product.product_id);
  };

  const itemPrice = formatPriceToVietnameseCurrency(product.sale_price, 'đ');
  const itemTotalPrice = formatPriceToVietnameseCurrency(
    product.quantity * product.sale_price,
    'đ'
  );

  return (
    <Flex
      className={classNames(
        'ordered-item',
        activeProductId === product.product_id && 'ordered-item-active'
      )}
      justify="space-between"
      onClick={handleSelectItem}
      gap={32}
    >
      <Flex vertical flex={1} gap={8} className="ordered-item-details">
        <div>{product.product_name}</div>

        <Flex gap={12}>
          <span>{itemPrice}</span>
          <span className="details-total-price">&#8226;</span>
          <span className="details-total-price">{itemTotalPrice}</span>
        </Flex>
      </Flex>

      <Flex align="start">
        <Flex align="center" gap={16}>
          <MinusIconSmall
            className="ordered-item-action"
            onClick={() => handleUnSelectProduct(product.product_id)}
            style={{ cursor: 'pointer' }}
          />
          {product.quantity}
          <PlusIconSmall
            className="ordered-item-action"
            onClick={() => handleSelectProduct(product)}
            style={{ cursor: 'pointer' }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
