import { OrderDishContextProvider } from 'contexts/OrderDishContext';
import 'styles/pages/order-dish.scss';
import { OrderedSummary } from './OrderedSummary';
import { OrderMenuItems } from './OrderMenuItems';
import { OrderMenuTypes } from './OrderMenuTypes';
import { OrderPayment } from './OrderPayment';

const OrderDish = () => {
  return (
    <OrderDishContextProvider>
      <div className="pos-order-dish">
        <div className="order-dish-col ordered-summary">
          <OrderedSummary />

          <OrderPayment />
        </div>

        <div className="order-dish-col ordering-dishes">
          <OrderMenuTypes />

          <OrderMenuItems />
        </div>
      </div>
    </OrderDishContextProvider>
  );
};

export default OrderDish;
