import { getToken } from 'services/storages/userStorage';

const PAGES = {
  auth: '/auth',
  login: '/login',
  register: '/register',
  home: '/',
  orderDish: '/order-dish'
};

export function getRedirectUrl(redirect: string) {
  const token = getToken();
  return `${String(process.env.REACT_APP_WEB_URL)}/#${
    PAGES.auth
  }?token=${token}&redirect=${encodeURIComponent(redirect)}`;
}

export const PATTERN_URL = {
  HOME: '/'
};

export default PAGES;
