import { useQuery } from '@tanstack/react-query';
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import apis from 'services/api';
import QUERY_KEYS from 'services/api/queryKeys';
import { Categories, Category, Product } from 'services/api/type/category.type';

export type OrderedProduct = Product & {
  quantity: number;
};

type OrderDishContextType = {
  dishItems: Product[];
  orderedProducts: OrderedProduct[];
  currentCategory: Category | null;
  categories: Categories;
  tax: number;
  activeProductId: Product['product_id'] | null;
  setActiveProductId: Dispatch<SetStateAction<number | null>>;
  handleSelectProduct: (product: Product) => void;
  handleUnSelectProduct: (productId: Product['product_id']) => void;
  handleUpdateOrderedProductQuantity: (quantity: number) => void;
  handleUpdateOrderedProductPrice: (price: string) => void;
  handleRemoveOrderedProductPrice: () => void;
  handleUpdateCurrentCategory: (categoryId: number) => void;
};

const OrderDishContext = createContext<OrderDishContextType | undefined>(undefined);

export const useOrderDishContext = () => {
  const contextValue = useContext(OrderDishContext);

  if (!contextValue) {
    throw new Error('Context must be used within a Provider');
  }

  return contextValue;
};

export const OrderDishContextProvider = ({ children }: PropsWithChildren) => {
  const [orderedProducts, setOrderedProducts] = useState<OrderedProduct[]>([]);
  const [activeProductId, setActiveProductId] = useState<Product['product_id'] | null>(null);

  const [currentCategory, setCurrentCategory] = useState<Category | null>(null);

  const [tax] = useState(1.7);

  const { data: categories = [] } = useQuery({
    queryKey: [QUERY_KEYS.GET_CATEGORIES],
    queryFn: () => apis.category.getCategories(),
    staleTime: 30 * 1000
  });

  const dishItems = useMemo(() => {
    if (currentCategory) {
      return currentCategory.products;
    }

    return [];
  }, [currentCategory]);

  const handleSelectProduct = useCallback((product: Product) => {
    setActiveProductId(product.product_id);

    setOrderedProducts(prevState => {
      const selectedItem = prevState.find(item => item.product_id === product.product_id);

      if (selectedItem) {
        const newItems = prevState.map(item => {
          const quantity =
            selectedItem.product_id === item.product_id ? item.quantity + 1 : item.quantity;
          return {
            ...item,
            quantity
          };
        });

        return newItems;
      }

      return prevState.concat({ ...product, quantity: 1 });
    });
  }, []);

  const handleUnSelectProduct = useCallback((productId: Product['product_id']) => {
    setActiveProductId(productId);

    setOrderedProducts(prevState => {
      const selectedItem = prevState.find(item => item.product_id === productId);

      if (!selectedItem) {
        return prevState;
      }

      if (selectedItem.quantity === 1) {
        setActiveProductId(null);

        if (prevState.length === 1) {
          return [];
        }

        const newItems = prevState.filter(item => item.product_id !== selectedItem.product_id);
        return newItems;
      }

      const newItems = prevState.map(item => {
        const quantity =
          selectedItem.product_id === item.product_id ? item.quantity - 1 : item.quantity;

        return {
          ...item,
          quantity
        };
      });

      return newItems;
    });
  }, []);

  const handleUpdateCurrentCategory = useCallback(
    (categoryId: number) => {
      const category = categories.find(c => c.category_id === categoryId);
      setCurrentCategory(category ?? null);
    },
    [categories]
  );

  const handleUpdateOrderedProductQuantity = useCallback(
    (quantity: number) => {
      if (!activeProductId) return;

      if (quantity === 0) {
        handleUnSelectProduct(activeProductId);
        return;
      }

      setOrderedProducts(prevState =>
        prevState.map(item => {
          if (item.product_id === activeProductId) {
            return {
              ...item,
              quantity
            };
          }

          return item;
        })
      );
    },
    [activeProductId, handleUnSelectProduct]
  );

  const handleRemoveOrderedProductPrice = useCallback(() => {
    if (!activeProductId) return;
    setOrderedProducts(prevState =>
      prevState.map(item => {
        if (item.product_id === activeProductId) {
          return {
            ...item,
            price: 0
          };
        }

        return item;
      })
    );
  }, [activeProductId]);

  const handleUpdateOrderedProductPrice = useCallback(
    (price: string) => {
      if (!activeProductId) return;

      setOrderedProducts(prevState =>
        prevState.map(item => {
          if (item.product_id === activeProductId) {
            const newPriceStr = item.sale_price ? item.sale_price + price : price;
            const newPrice = Number.parseInt(newPriceStr);
            console.log({ newPrice, newPriceStr, item });

            return {
              ...item,
              price: newPrice
            };
          }

          return item;
        })
      );
    },
    [activeProductId]
  );

  useEffect(() => {
    if (categories.length) {
      setCurrentCategory(categories[0]);
    }
  }, [categories]);

  const contextValue = useMemo<OrderDishContextType>(
    () => ({
      orderedProducts: orderedProducts,
      dishItems,
      currentCategory,
      categories,
      tax,
      activeProductId: activeProductId,
      setActiveProductId,
      handleSelectProduct,
      handleUnSelectProduct,
      handleUpdateOrderedProductQuantity,
      handleUpdateOrderedProductPrice,
      handleRemoveOrderedProductPrice,
      handleUpdateCurrentCategory
    }),
    [
      orderedProducts,
      dishItems,
      currentCategory,
      categories,
      tax,
      activeProductId,
      setActiveProductId,
      handleSelectProduct,
      handleUnSelectProduct,
      handleUpdateOrderedProductQuantity,
      handleUpdateOrderedProductPrice,
      handleRemoveOrderedProductPrice,
      handleUpdateCurrentCategory
    ]
  );

  return <OrderDishContext.Provider value={contextValue}>{children}</OrderDishContext.Provider>;
};
