import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import classNames from 'classnames';
import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { useGetMe } from 'hooks/useGetMe';
import Header from './components/common/header';

import { logPageView } from 'services/tracking/gaTracking';

function App() {
  const location = useLocation();

  const { isLoading } = useGetMe();

  useEffect(() => {
    logPageView(location.pathname + location.search);
  }, [location]);

  if (isLoading) {
    return <Spin fullscreen indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />} />;
  }

  return (
    <div className="pos-app">
      <Header />
      <main className={classNames('pos-app-content', {})}>
        <Outlet />
      </main>
    </div>
  );
}

export default App;
