import { Flex } from 'antd';
import { ReactComponent as AvailableTableIcon } from 'assets/images/available-table-icon.svg';
import { ReactComponent as ChairIcon } from 'assets/images/chair-icon.svg';
import { ReactComponent as InUsedTableIcon } from 'assets/images/inused-table-icon.svg';
import { ReactComponent as ReservedTableIcon } from 'assets/images/reserved-table-icon.svg';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import PAGES from 'routes/constants';
import { TableStatus } from 'services/api/type/floor.type';

type TableItemProps = {
  status: TableStatus;
  seats: number;
  tableId: number;
};

const tableClassNameMap: Record<TableStatus, string> = {
  available: 'tables-item-empty',
  'in-used': 'tables-item-serving',
  reserved: 'tables-item-reserved'
};

const tableIconMap = {
  available: AvailableTableIcon,
  'in-used': InUsedTableIcon,
  reserved: ReservedTableIcon
};

export const TableItem = ({ tableId, status, seats }: TableItemProps) => {
  const navigate = useNavigate();

  const itemClassName = tableClassNameMap[status];
  const TableIcon = tableIconMap[status];

  const handleSelectTable = () => {
    const path = `${PAGES.orderDish}?tableId=${tableId}`;
    navigate(path);
  };

  return (
    <div
      className={classNames(itemClassName, 'tables-list-item flex-items-center')}
      onClick={handleSelectTable}
    >
      <span className="tables-list-item-text flex-items-center">{tableId.toString()}</span>

      <div style={{ position: 'relative' }}>
        <TableIcon />

        {seats && (
          <Flex className="tables-list-item-chairs" align="center" justify="center">
            <span className="chairs-number">{seats}</span>
            <ChairIcon />
          </Flex>
        )}
      </div>
    </div>
  );
};
