import { useQuery } from '@tanstack/react-query';
import { Flex } from 'antd';
import { ReactComponent as FloorIcon } from 'assets/images/floor-icon.svg';
import { ReactComponent as OrderIcon } from 'assets/images/order-icon.svg';
import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PAGES from 'routes/constants';
import apis from 'services/api';
import QUERY_KEYS from 'services/api/queryKeys';
import { Floor, TableStatus } from 'services/api/type/floor.type';
import 'styles/pages/tables-list.scss';
import { TableItem } from './TableItem';

type MenuItemKey = 'total' | TableStatus;

type MenuItem = { key: MenuItemKey; label: string; className: string };

const defaultMenuItems: MenuItem[] = [
  {
    key: 'total',
    label: 'Tất cả',
    className: 'tables-menu-item-total'
  },
  {
    key: 'reserved',
    label: 'Bàn đã đặt',
    className: 'tables-menu-item-reserved'
  },
  {
    key: 'in-used',
    label: 'Bàn có khách',
    className: ''
  },
  {
    key: 'available',
    label: 'Bàn trống',
    className: 'tables-menu-item-empty'
  }
];

const mockPosId = 1;

const TablesList = () => {
  const [currentFloor, setCurrentFloor] = useState<Floor>();
  const [tableType, setTableType] = useState<MenuItemKey>('total');
  const navigate = useNavigate();

  const { data: floors = [] } = useQuery({
    queryKey: [QUERY_KEYS.GET_FLOORS],
    queryFn: () => apis.floor.getFloors(mockPosId),
    select: data => data.floors,
    staleTime: 30 * 1000
  });

  const handleChangeFloor = (floorId: number) => {
    const floor = floors.find(floorItem => floorItem.floor_id === floorId);
    setCurrentFloor(floor);
  };

  const handleChangeTableType = (key: MenuItemKey) => {
    setTableType(key);
  };

  const handleClickOrder = () => {
    navigate(PAGES.orderDish);
  };

  useEffect(() => {
    if (floors && floors.length) {
      setCurrentFloor(floors[0]);
    }
  }, [floors]);

  // const currentFloor = useMemo(() => floors.find((floor) => floor.floor_id === currentFloor), [currentFloor, floors]);

  const tablesList = useMemo(() => {
    if (!currentFloor) {
      return [];
    }

    const tables = currentFloor.tables;

    if (tableType === 'total') {
      return tables;
    }

    return tables.filter(table => table.status === tableType);
  }, [currentFloor, tableType]);

  const menuItems = useMemo<MenuItem[]>(() => {
    const currentTables = currentFloor?.tables || [];

    const tableNumberMap: Record<MenuItemKey, number> = {
      total: currentTables.length,
      'in-used': currentTables.filter(table => table.status === 'in-used').length,
      reserved: currentTables.filter(table => table.status === 'reserved').length,
      available: currentTables.filter(table => table.status === 'available').length
    };

    return defaultMenuItems.map(item => ({
      ...item,
      label: `${item.label} (${tableNumberMap[item.key]})`
    }));
  }, [currentFloor?.tables]);

  return (
    <div className="pos-tables-list">
      <Flex className="tables-menu" align="center" justify="center">
        {menuItems.map(item => (
          <div
            key={item.key}
            className={classNames(
              item.className,
              'tables-menu-item',
              item.key === tableType && 'tables-menu-item-active'
            )}
            onClick={() => handleChangeTableType(item.key)}
          >
            {item.label}
          </div>
        ))}
      </Flex>

      <div className="tables-list">
        {tablesList.map(table => (
          <TableItem
            key={table.table_id}
            tableId={table.table_id}
            status={table.status}
            seats={table.seats}
          />
        ))}
      </div>

      <Flex className="tables-floors-list" vertical>
        {floors.map(floor => (
          <Flex
            key={floor.floor_id}
            className={classNames(
              'floors-item',
              floor.floor_id === currentFloor?.floor_id && 'floors-item-active'
            )}
            align="center"
            justify="center"
            gap={8}
            vertical
            onClick={() => handleChangeFloor(floor.floor_id)}
          >
            <FloorIcon className="floors-item-icon" />

            {floor.floor}
          </Flex>
        ))}
      </Flex>

      <Flex
        className="tables-order-button"
        justify="center"
        align="center"
        vertical
        gap={8}
        onClick={handleClickOrder}
      >
        <OrderIcon />
        Order
      </Flex>
    </div>
  );
};

export default TablesList;
