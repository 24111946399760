import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import { getToken } from 'services/storages/userStorage';
import PAGES from './constants';

interface Props {
  children: ReactNode;
}

const PrivateRoutes = ({ children }: Props) => {
  const token = getToken();

  if (!token) {
    return <Navigate to={PAGES.login} />;
  }

  return <>{children}</>;
};

export default PrivateRoutes;
